{
  "read_more": "Lire la suite",
  "offered_services": "Services offerts",
  "about": {
    "title": "À propos",
    "message": "",
    "header": "À propos",
    "jubo": {
      "title": "Jubo",
      "message": "Etymologiquement, Jubo en wolof, la langue nationale et véhiculaire au Sénégal, signifie faire la paix ou l’action de faire la paix. Le terme a inspiré le nom de la structure Jubo Service de Médiation et Consultance (JSMC).",
    },
    "philosophy": {
      "title": "Philosophie",
      "message":"Les épreuves peuvent endurcir les cœurs, impacter négativement les relations et affecter toute lueur d’espoir d’un nouveau départ. Cependant, pourvu que, de part et d’autre, la bonne volonté ait le dessus sur l’ego, tout devient possible chez Jubo Service de Médiation et Consultance (JSMC) où « des roses peuvent même pousser du béton ». C’est cet état d’esprit positif qui nous fait dire : Jubo ! Quand la médiation permet de re.définir les relations et de restaurer les liens brisés !",
    }
  },
  "home": {
    "title": "Accueil",
    "message":"Biagui Alexandre Nadialine est un généraliste de la médiation. Il est agréé en matières civile et commerciale en 2016, puis en matière pénale en 2020. Il est également un académique passionné de la recherche. Il a obtenu deux Masters à l’Université du Luxembourg, dont l’un en multilinguisme et multiculturalisme (2008-2010) et l’autre en médiation (2009-2013). En 2019, il a obtenu de la même institution son doctorat en sciences politiques, à travers lequel il s’est plus focalisé sur la notion de la sécurité humaine et la médiation politique. Depuis 2015, Biagui Alexandre Nadialine intervient en tant que médiateur interculturel pour le compte du Service de la scolarisation des enfants étrangers (SECAM) du Ministère de l’Éducation nationale, de l’Enfance et de la Jeunesse du Luxembourg (MENEJ). Sa passion pour la médiation le pousse à s’intéresser à une variété de types de médiation. Biagui parle 6 langues et est passionné par l’apprentissage de nouvelles langues et la rencontre de nouvelles cultures. Il est d’avis que le multilinguisme et le multiculturalisme peuvent faciliter le dialogue interculturel et privilégier le rapprochement ; étant donné que la langue est un vecteur essentiel qui peut favoriser une meilleure compréhension de l’autre et de sa culture. ",
    "header":"Accueil"
  },
  "services":{
    "title": "Services",
    "header": "Services",
    "mediation": {
      "title": "Médiation",
      "message": "La médiation est un processus consensuel, à travers lequel, le médiateur, une personne qualifiée, facilite les discussions entre parties en désaccord en vue de la résolution du conflit qui les oppose, tout en s’abstenant de donner son avis ou d’être du côté de l’une des parties en conflit. Ainsi, si : - vous souhaitez éviter les procédures judiciaires publiques longues, coûteuses et stressantes … - vous souhaitez accordez une chance au dialogue dans la plus stricte confidentialité … - vous souhaitez trouver un accord amiable selon vos propres termes et maintenir vos relations ou certaines de vos relations … Dans ce cas, essayer la médiation proposée par Jubo Service de Médiation et Consultance (JSMC), sachant qu’à tout moment vous pouvez arrêter le processus quand vous estimez qu’il n’est plus adapté à votre problème ou qu’il ne permet pas de défendre vos intérêts."
    },
    "conciliation": {
      "title": "Conciliation",
      "message": "La conciliation est une démarche consensuelle de résolution des conflits à travers laquelle, le conciliateur, contrairement au médiateur, peut formuler des suggestions aux parties en conflit en vue de la résolution du conflit qui les oppose. Ainsi, si : - vous souhaitez éviter les procédures judiciaires publiques longues, coûteuses et stressantes … - vous souhaitez accordez une chance au dialogue dans la plus stricte confidentialité … - vous souhaitez trouver un accord amiable selon vos propres termes et maintenir vos relations ou certaines de vos relations … Dans ce cas, essayer la conciliation proposée par Jubo Service de Médiation et Consultance (JSMC), sachant qu’à tout moment vous pouvez arrêter le processus quand vous estimez qu’il n’est plus adapté à votre problème ou qu’il ne permet pas de défendre vos intérêts.",
    },
    "human_security_consulting": {
      "title": "Consultance en sécurité humaine",
      "message": "Protéger l’être humain et ses biens contre toutes menaces connues, le prévenir contre de potentielles menaces, le soulager et garantir son épanouissement sont des points sur lesquels Jubo Service de Médiation et Consultance (JSMC) se focalise. Ainsi, nos services pourraient vous intéresser si vous êtes : - une organisation qui veille au respect des droits de l’Homme ou de thématiques similaires - une entreprise souhaitant investir dans une zone à haut risque - un État souhaitant déployer les efforts nécessaires pour assurer la sécurité et le bien-être à vos citoyens Vous pouvez profiter, selon vos besoins, d’: - analyses typologiques de menaces liées à la sécurité humaine - analyses interactionnelles, géopolitiques et géostratégiques - analyses SWOT de projets à monter dans des zones à risques Jubo Service de Médiation et Consultance (JSMC) se fera le plaisir de mener des analyses critiques et impartiales qui se basent sur des données fiables d’une veille de 571 indicateurs perfectibles et adaptables, associés à un logiciel multifonctionnel de synthèse d’informations et de prospectif de développement économique durable.",
    },
    "research_and_proofreading": {
      "title":"Recherche et Relecture",
      "message":"La recherche et la relecture de documents font également partie des services que l’on vous propose. En fonction de vos besoins et attentes, une recherche sera menée, pendant que vos articles, votre mémoire ou thèse en sciences humaines et sciences sociales seront méticuleusement passés en revue dans la plus stricte confidentialité et dans les délais impartis.",
    },
    "fm": "• Médiation familiale",
    "mm": "• Médiation conjugale",
    "cm": "• Médiation en entreprise",
    "hm": "• Médiation civile (conflict patrimonial)",
    "nm": "• Médiation de voisinage",
    "pm": "• Médiation politique nationale",
    "pmi": "• Médiation politique internationale",
    "fc": "• Conciliation familiale",
    "mc": "• Conciliation conjugale",
    "cw": "• Conciliation en entreprise",
    "cc": "• Conciliation civile (conflict patrimonial)",
    "nc": "• Conciliation de voisinage",
    "pc": "• Conciliation politique nationale",
    "pci": "• Conciliation politique internationale"
  },
  "fees": {
    "title": "Honoraires",
    "header": "Honoraires",
    "mediation":"Médiation",
    "conventional":"Conventionnelle",
    "judicial": "Judiciaire",
    "im":"Session d’information",
    "fm": "Médiation familiale",
    "mm": "Médiation conjugale",
    "em": "Médiation économique",
    "cm": "Médiation civile",
    "nm": "Médiation de quartier",
    "hour" : "heure",
    "conciliation": "Conciliation",
    "fc": "Conciliation familiale",
    "mc": "Conciliation conjugale",
    "ec": "Conciliation économique",
    "cc": "Conciliation civile",
    "nc": "Conciliation de quartier",
    "bs": "Entre époux",
    "bf": "Entre membre d’une famille",
    "iw": "En entreprise ",
    "hc": "Conflit patrimonial ",
    "neighbourhood": "Voisinage ",
    "td": "Détails transactionnels",
    "payment": "Payement",
    "os": "Sur place",
    "vc": "Carte Visa",
    "dc": "Carte débit",
    "cash": "Cash",
    "bt":"Virement bancaire",
    "free": "Gratuite",
    "pd": {
      "title": "Délai de payement",
      "message": "14 jours en cas de virement bancaire"
    },
    "penalty": {
      "title": "Pénalité",
      "message": "1 Euro/jour au-delà des 14 jours prévus, 5 Euros/jour au-delà de la prolongation prévue"
    },
    "cancellation": {
      "title": "Annulation de la réservation",
      "message": "24 heures avant le rendez-vous",
      "penalty": "1 heure de séance facturée en cas de non-annulation 24 heures avant le rendez-vous"
    }
  },
  "partners": {
    "header": "Partenariat"
  },
  "contact": {
    "title": "Contact",
    "header": "Contact",
    "location": "Adresse",
    "phone": "Phone",
    "email": "Courriel",
    "web": "Site internet",
    "mobile": "GSM",
    "office": "Fixe"
  },
  "title": "Généraliste de la Médiation",
  "English": "English",
  "French": "Français",
  "German": "Deutsche",
  "mediation_laws": {
    "title": "Liens vers la loi relative à la médiation",
    "laws": ["Loi du 24.02.2012 relative à la médiation civile et commerciale.",
      "Loi du 06.05.1999 relative à la médiation pénale.",
      "Recommandation N° R (98) 1 du Comité des ministres du Conseil de l’Europe.",
      "Règlement général sur la protection des données (RGDP).",
      "Master professionnel en médiation, Université du Luxembourg",
      "Règlement grand-ducal du 25.06.2012 fixant la procédure d’agrément aux fonctions de médiateur judiciaire et familial, le programme de la formation spécifique en médiation et la tenue d’une réunion d’information gratuite.",
      "Règlement grand-ducal du 31.05.1999 fixant les critères et la procédure d’agrégation aux fonctions de médiateur, la procédure de médiation pénale et le mode de rémunération des médiateurs."],
    "links": {
      "0":"http://data.legilux.public.lu/file/eli-etat-leg-memorial-2012-37-fr-pdf.pdf",
      "1":"http://data.legiluÉx.public.lu/file/eli-etat-leg-memorial-1999-67-fr-pdf.pdf",
      "2":"https://rm.coe.int/16804ede1c",
      "3":"https://eur-lex.europa.eu/legal-content/FR/TXT/PDF/?uri=CELEX:32016R0679",
      "4":"https://wwwfr.uni.lu/formations/fhse/master_en_mediation",
      "5":"http://data.legilux.public.lu/file/eli-etat-leg-memorial-2012-134-fr-pdf.pdf",
      "6":"http://data.legilux.public.lu/file/eli-etat-leg-memorial-1999-67-fr-pdf.pdf",
    }
  },
  "view": "Voir",
  "footer": {
    "code1": "Autorisation  10129614 / 0",
    "code2": "RCS  B255694"
  }
}