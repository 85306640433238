{
  "read_more": "Weiterlesen",
  "offered_services": "Angebotene Dienstleistungen",
  "about": {
    "title": "Über",
    "message": "",
    "header": "Über",
    "jubo": {
      "title": "Jubo",
      "message": "Jubo stammt von der national- und Verkehrssprache Senegals, wolof, und bedeutet Frieden oder die Herstellung des Friedens. Jedoch, wurde Jubo Service de Médiation et Consultance (JSMC) S.à.r.l.-S. von dem Begriff inspiriert.",
    },
    "philosophy": {
      "title": "Grundgedanken",
      "message":"Lebenserfahrungen können das Herz abhärter, Beziehungen negativ beeinflussen und jeden Hoffungsschimmer eines Neuanfangs beeinträchtigen. Jedoch, solange auf beiden Seiten der Wille über das Ego steht, ist alles möglich bei Jubo Service de Médiation et Consultance (JSMC) S.à.r.l.-S., wo « Rosen sogar aus Beton wachsen können ». Diese positive Einstellung lässt uns sagen : Jubo ! Wenn die Vermittlung es ermöglicht, Beziehungen zu definieren und die zerbrochenen Verbindungen wiederherzustellen !",
    }
  },
  "home": {
    "title": "Startseite",
    "message":"Biagui Alexandre Nadialine ist Vermittler. Er beschäfttigt sich mit verschiedenen Vermittlungsbereichen. In 2016 wurde er in Zivil- und Handelssachen, dann in 2020 in Strafsachen zugelassen. Er ist Wissenschaftler und hat zwei Masterstudiengänger and der Universität Luxemburg erhalten, einen in Mehrsprachigkeit und Multikulturalität (2008-2010) und einen anderen in Mediation (2009-2013). In 2019 erwarb er an derselben Universität seinen Doktortitel in Politikwissenschaft, wobei er sich auf menschlicher Sicherheit und politischer Vermittlung konzentrierte. Seit 2015 fungiert Biagui als interkultureller Mediator für die Abteilung Schulbildung für ausländischer Kinder (SECAM) des luxemburgischen Ministerium für Bildung, Kinder und Jugend (MENEJ). Seine Interesse für Mediation veranlast hin, sich für eine Vielzahl von  Mediationsarten zu interessieren. Biagui spricht sechs Sprachen und interessiert sich für das Erlernen neuer Sprachen sowie das Kennenlernen neuer Kulturen. Er ist der Meinung, dass Mehrsprachigkeit und Multikulturalität den interkulturellen Dialog und der Annäherung verschidener Kulturen erleichtern können, da die Sprache ein wesentlicher Faktor ist, der ein besseres Verständnis des anderen und seiner Kultur fördern kann.",
    "header":"Startseite"
  },
  "services":{
    "title": "Dienstleistungen",
    "header": "Dienstleistungen",
    "mediation": {
      "title": "Mediation",
      "message": "Die Mediation ist ein einvernehmlicher Prozess, bei dem der Mediator, eine qualifizierte Person, die Gespräche zwischen Parteien eines Konflikts erleichtert und sich keiner der Konfliktparteien anschliesst. Möchten Sie : - lange stressige und köstliche öffentliche gerichtliche Prozesse vermeiden ? - mit streng vertaulichkeit dem dialog eine Chance geben ? - eine gütliche Einigung zu ihren eigenen Bedingungen erzielen und Ihre Beziehungen oder einige Ihrer Beziehungen aufrecht erhalten ? Denn probieren Sie aus, die von Jubo Service de Médiation et Consultance (JSMC) S.à.r.l.-S. angebotene Mediation, wobei Sie jederzeit den Prozess aufhören können, wenn Sie der Meinung sind, dass die Mediation nicht mehr Ihr Problem anpasst oder Ihre Interessen verteidigen kann."
    },
    "conciliation": {
      "title": "Schlichtung",
      "message": "Die Schlichtung ist ein einvernehmlicher Ansatz zur Beilegung von Streitigkeiten, bei dem der Schlichter im Gegensatz zum Mediator den Konfliktparteien Vorschläge zur Beilegung des Konflikts unterbreiten (machen) kann. Möchten Sie : - lange stressige und köstliche öffentliche gerichtliche Prozesse vermeiden ? - mit streng vertaulichkeit dem dialog eine Chance geben ? - eine gütliche Einigung zu ihren eigenen Bedingungen erzielen und Ihre Beziehungen oder einige Ihrer Beziehungen aufrecht erhalten ? Denn, versuchen Sie die von Jubo Service de Médiation et Consultance (JSMC) S.à.r.l.-S. vorgeschlagene Schlichtung, wobei Sie das Verfahren jederzeit beenden können, wenn Sie der Auffassung sind, dass die Schlichtung Ihr Probelm nicht mehr anpasst oder Ihre Interessen nicht mehr verteidigen kann.",
    },
    "human_security_consulting": {
      "title": "Beratung im Bereich der menschlichen Sicherheit",
      "message": "Menschenschutz vor allen potenziellen Bedrohungen und ihre Folgenlinderung sind Punkte, auf die Jubo Service de Médiation et Consultance (JSMC) S.à.r.l.-S. sich konzentriert. Sind Sie : - eine Organisation, die die Achtung der Menschenrechte oder ähnlicher Themen überwacht - ein Unternehmen, das in ein Hochrisikogebiet investieren möchte - ein Staat, der die notwendigen Anstrengungen unternehmen will, um Sicherheit und Wohlergehen seiner Bürger zu gewährleisten Dann würde unsere Dienste Sie bestimmt interessieren und bei Bedarf können Sie von folgenden Vorteilen profitieren : - typologische Analysen von Bedrohungen der menschlichen Sicherheit - interaktive, geopolitische und geostrategische Analysen - SWOT-Analysen von Projekten zum Aufbau in Hochrisikogebieten Jubo Service de Médiation et Consultance (JSMC) S.à.r.l.-S. wird sich freuen, kritische und unparteiische Analysen auf der Grundlage von 571 Indikatoren, in Verbindung mit einer multifunktionalen Software für Informationssynthese und nachhaltige Wirtschaftsentwicklung, durchzuführen, die eine zuverlässige Datenerhebung ermöglichen. ",
    },
    "research_and_proofreading": {
      "title":"Forschung und Lektüre",
      "message":"Forschung und Lektüre von Dokumenten auf Französich sind ebenfalls von Jubo Service de Médiation et Consultance (JSMC) S.à.r.l.-S. angeboten. Je nach Ihren Bedürfnissen und Erwartungen wird eine Forschungsarbeit für Sie durchgeführt, während Ihre Artikel, Masterarbeit oder Dissertation in den Geistes- und Sozialwissenschaften streng vertraulich und innerhalb der vorgegebenen Fristen geprüft werden.",
    },
    "fm": "• Familienmediation",
    "mm": "• Ehepaarmediation",
    "cm": "• Mediation in einem Unternehmen",
    "hm": "• Zivilmediation (Konflikt im Güterrecht)",
    "nm": "• Nachbarschaftsmediation",
    "pm": "• Politische Mediation auf nationaler Ebene",
    "pmi": "• Politische Mediation auf internationaler Ebene",
    "fc": "• Familienschlichtung",
    "mc": "• Ehepaarschlichtung",
    "cw": "• Schlichtung in einem Unternehmen",
    "cc": "• Zivilschlichtung (Konflikt im Güterrecht)",
    "nc": "• Nachbarschaftsschlichtung",
    "pc": "• Politische Schlichtung auf nationaler Ebene",
    "pci": "• Politische Schlichtung auf internationaler Ebene"
  },
  "fees": {
    "title": "Honorare",
    "header": "Honorare",
    "mediation":"Mediation",
    "conventional":"Konventionelle",
    "judicial":"Gerichtliche",
    "im":"Informationssitzung",
    "fm": "Familienmediation",
    "mm": "Ehepaarmediation",
    "em": "Wirtschaftmediation",
    "cm": "Zivilmediation",
    "nm": "Nachbarschaftsmediation",
    "hour" : "stunde",
    "conciliation": "Schlichtung",
    "fc": "Familienschlichtung",
    "mc": "Ehepaarschlichtung",
    "ec": "Wirtschaftschlichtung",
    "cc": "Zivilschlichtung",
    "nc": "Nachbarschaftsschlichtung",
    "bs": "Zwischen Ehegatten ",
    "bf": "Unter Familienangehörigen",
    "iw": "Mediation in einem Unternehmen ",
    "hc": "Konflikt im Güterrecht ",
    "neighbourhood": "Zwischen Nachbarn",
    "td": "Transaktionsdetails",
    "payment": "Zahlungsmöglichkeiten",
    "os": "Vor Orte",
    "vc": "Visakarte",
    "dc": "Bankkarte",
    "cash": "Cash",
    "bt":"Banküberweisung",
    "free": "Kostenlos",
    "pd": {
      "title": "Zahlungsfrist",
      "message": "14 Tage bei Banküberweisung"
    },
    "penalty": {
      "title": "Zahlungsstrafe",
      "message": "1 Euro pro Tag über die 14 vorgesehenen Tage hinaus, 5 Euro pro Tag über die Verlängerungsfrist hinaus"
    },
    "cancellation": {
      "title": "Buchungannulierung",
      "message": "24 Stunden vor dem Termin",
      "penalty": "1 Stunde Sitzungszeit in Rechnung gestellt bei Nichtanmeldung 24 Stunden vor dem Termin"
    }
  },
  "partners": {
    "header": "Partnerschaft"
  },
  "contact": {
    "title": "Kontakt",
    "header": "Kontakt",
    "location": "Adresse",
    "phone": "Büro",
    "email": "Email",
    "web": "Webseite",
    "mobile": "Handy",
    "office": "Feste Telefonnumer"
  },
  "title": "Vermittler",
  "English": "English",
  "French": "Français",
  "German": "Deutsche",
  "mediation_laws": {
    "title": "Mediation Laws",
    "laws": ["Gesetz vom 24.02.2012 über die zivil- und handelsrechtliche Mediation.",
      "Gesetz vom 06.05.1999 über die Mediation in Strafsachen.",
      "Empfehlung Nr. R (98) 1 des Ministerkomitees des Europarats.",
      "Allgemeine Datenschutzverordnung.",
      "Master in Mediation, Universität Luxembourg",
      "Großherzogliche Verordnung vom 25.06.2012 zur Festlegung des Verfahrens für die Zulassung zum gerichtlichen- und Familienmediator, des Programms der spezifischen Mediationsausbildung und der Abhaltung einer kostenlosen Informationssitzung.",
      "Großherzogliche Verordnung vom 31.05.1999 zur Festlegung der Kriterien und des Verfahrens für die Aufgaben des Mediators, des Verfahrens der gerichtlichen Mediation und der Art der Vergütung der Mediatoren."],
    "links": {
      "0":"http://data.legilux.public.lu/file/eli-etat-leg-memorial-2012-37-fr-pdf.pdf",
      "1":"http://data.legilux.public.lu/file/eli-etat-leg-memorial-1999-67-fr-pdf.pdf",
      "2":"https://rm.coe.int/16804ede1c",
      "3":"https://eur-lex.europa.eu/legal-content/FR/TXT/PDF/?uri=CELEX:32016R0679",
      "4":"https://wwwfr.uni.lu/formations/fhse/master_en_mediation",
      "5":"http://data.legilux.public.lu/file/eli-etat-leg-memorial-2012-134-fr-pdf.pdf",
      "6":"http://data.legilux.public.lu/file/eli-etat-leg-memorial-1999-67-fr-pdf.pdf ",
    }
  },
  "view": "Siehe",
  "footer": {
    "code1": "Genehmigung 10129614 / 0",
    "code2": "RCS B255694"
  }
  
}