{
  "read_more": "Read More",
  "offered_services": "Offered Services",
  "about": {
    "title": "About Me",
    "message": "",
    "header": "About",
    "jubo": {
      "title": "Jubo",
      "message": "Jubo comes from wolof, the national language and lingua franca in Senegal. It refers to peace-making or is used to express the action of making peace. This inspired the name Jubo Service de Médiation et Consultance (JSMC).",
    },
    "philosophy": {
      "title": "Philosophy",
      "message":"Trials can harden hearts, negatively impact relationships and affect any glimmer of hope for a new start. However, provided that on both sides, good will has the upper hand on ego, revenge and punishment, everything becomes possible at Jubo Service de Médiation et Consultance (JSMC) where 'Roses can even grow from Concrete'. It is this positive mindset that makes us say: Jubo ! When mediation helps redefines relations and restore broken ties ! ",
    }
  },
  "home": {
    "title": "Home",
    "message":"Biagui Alexandre Nadialine is a general mediation practitioner. He got certified in civil and commercial matters in 2016 and in penal matter in 2020. He is an academic and passionate about research. He has two Masters from the University of Luxembourg, one in multilinguism and multiculturalism (2008-2010) and the other in mediation (2009-2013). In 2019, he obtained from the same institution his PhD in political science, focusing on human security and political mediation. Since 2015, Biagui Alexandre Nadialine has acted as an interculturel mediator for the Education Service for Foreign Children (SECAM) of Luxembourg’s Ministry of National Education, Children and Youth (MENEJ). His passion for mediation drives his interest in different fields of mediation. Biagui speaks 6 languages and is passionate about learning new languages and cultures. He believes that multilinguism and multiculturalism can facilitate intercultural dialogue and bring people together, since language is an important element that can foster a better understanding of the other and his/ her culture. ",
    "header":"Home"
  },
  "services":{
    "title": "Services",
    "header": "Services",
    "mediation": {
      "title": "Mediation",
      "message": "Mediation is a consensual process, through which, the mediator, a qualified person, facilitates discussions between parties in disagreement for the resolution of the conflict opposing them, while refraining him/herself from giving an opinion or being on the side of one of the parties in conflict. Therefore, - If you want to avoid lengthy, costly and stressful public legal proceedings - If you want to give a chance to Dialogue in the strictest confidentiality - If you want to reach a friendly agreement on your own terms and maintain the ties or some of your ties Then you should experience the whole mediation process at Jubo Service de Médiation et Consultance (JSMC), knowing that you can stop the mediation at any time you feel that it does not help your case or suit your interests."
    },
    "conciliation": {
      "title": "Conciliation",
      "message": "Conciliation is a consensual approach to conflict resolution, through which the conciliator, unlike the mediator, may make suggestions to the disputing parties for the resolution of the conflict opposing them.Therefore, - If you want to avoid lengthy, costly and stressful public legal proceedings - If you want to give a chance to Dialogue in the strictest confidentiality - If you want to reach a friendly agreement on your own terms and maintain the ties or some of your ties Then you should experience the whole conciliation approach at Jubo Service de Médiation et Consultance (JSMC), knowing that you can stop the conciliation process at any time you feel that it does not help your case or suit your interests.",
    },
    "human_security_consulting": {
      "title": "Human Security consulting",
      "message": "Protecting human beings and their property against all known threats, preventing them from potential ones, relieving them and guaranteeing their development are aspects on which Jubo Service de Médiation et Consultance (JSMC) focuses. Our services may be of interest if you are : - an Organization that ensures respect of human rights or similar issues - a Company wishing to invest in a high-risk area - a State wishing to make necessary efforts to ensure safety and well-being to your citizens According to your needs you can benefit from: - Typological analyses of human security threats - Interactive, geopolitical and geostrategic analyses - SWOT analyses of projects to be lunched in high risk areas Jubo Service de Médiation et Consultance (JSMC) would be delighted, to conduct critical and impartial analyses based on reliable data from 571 perfectible and adaptable indicators linked to a multifunctional information synthesis software for security and sustainable economic development purposes.",
    },
    "research_and_proofreading": {
      "title":"Research and Proofreading",
      "message":"Research and Proofreading are also part of the services we offer. According to your needs and expectations, a research will be conducted, while your articles, dissertation or thesis in humanities and social sciences will be meticulously and objectively reviewed in the strictest confidentiality and within the allotted timeframe.",
    },
    "fm": "• Family Mediation",
    "mm": "• Marital mediation",
    "cm": "• Mediation in workplace",
    "hm": "• Civil mediation (Heritage conflict)",
    "nm": "• Neighbourhood mediation",
    "pm": "• Political mediation at the national level",
    "pmi": "• Political mediation at the international level",
    "fc": "• Family conciliation",
    "mc": "• Marital conciliation",
    "cw": "• Conciliation in workplace",
    "cc": "• Civil conciliation (Heritage conflict)",
    "nc": "• Neighbourhood conciliation",
    "pc": "• Political conciliation at the national level",
    "pci":"• Political conciliation at the international level"
  },
  "fees": {
    "title": "Pricing",
    "header": "Fees",
    "mediation":"Mediation",
    "conventional":"Conventional",
    "judicial": "Judicial",
    "im":"Informational meeting",
    "fm": "Family mediation",
    "mm": "Marital mediation",
    "em": "Economic mediation",
    "cm": "Civil mediation",
    "nm": "Neighbourhood mediation",
    "hour" : "hr",
    "conciliation": "Conciliation",
    "fc": "Family conciliation",
    "mc": "Marital conciliation",
    "ec": "Economic conciliation",
    "cc": "Civil conciliation",
    "nc": "Neighbourhood conciliation",
    "bs": "Between spouses ",
    "bf": "Between family members",
    "iw": "In workplace ",
    "hc": "Heritage conflict",
    "neighbourhood": "Neighbourhood",
    "td": "Transaction Details",
    "payment": "Payment",
    "os": "On-site",
    "vc": "Visa Card",
    "dc": "Debit Card",
    "cash": "Cash",
    "bt":"Bank Transfer",
    "free": "Free",
    "pd": {
      "title": "Payment Deadline",
      "message": "14 days in case of bank transfer"
    },
    "penalty": {
      "title": "Penalty",
      "message": "1 Euro per day after day 14, 5 Euros per day after extension"
    },
    "cancellation": {
      "title": "Cancellation",
      "message": "24 hours before appointment",
      "penalty": "hours invoiced in case of non cancellation 24 hours prior to the appointment"
    }
  },
  "partners": {
    "header": "Partners"
  },
  "contact": {
    "title": "Contact",
    "header": "Contact",
    "location": "Location",
    "phone": "Phone",
    "email": "Email",
    "web": "Web",
    "mobile": "Mobile",
    "office": "Office"
  },
  "title": "General Mediation Practitioner",
  "English": "English",
  "French": "Français",
  "German": "Deutsche",
  "mediation_laws": {
    "title": "Mediation Laws",
    "laws": ["Grand-Duchy Regulation of 25.06.2012 defining the procedure for certification of the functions of judicial and family mediator, the program of specific training in mediation and the holding of a free informational meeting.",
      "Grand-Duchy Regulation of 31.05.1999 defining the criteria and procedure for aggregation for the functions of mediator, the criminal mediation procedure and the remuneration of mediators.",
      "Law of 24.02.2012 on Civil and Commercial Mediation.",
      "Law of 06.05.1999 on penal mediation.",
      "Professional Master in Mediation, University of Luxembourg.",
      "Recommendation N° R (98) 1 of the Committee of Ministers of the Council of Europe.",
      "General Data Protection Regulation (GDPR)."],
    "links": {
      "0":"http://data.legilux.public.lu/file/eli-etat-leg-memorial-2012-134-fr-pdf.pdf",
      "1":"http://data.legilux.public.lu/file/eli-etat-leg-memorial-1999-67-fr-pdf.pdf",
      "2":"http://data.legilux.public.lu/file/eli-etat-leg-memorial-2012-37-fr-pdf.pdf",
      "3":"http://data.legilux.public.lu/file/eli-etat-leg-memorial-1999-67-fr-pdf.pdf",
      "4":"https://wwwfr.uni.lu/formations/fhse/master_en_mediation",
      "5":"https://rm.coe.int/16804ede1c",
      "6":"https://eur-lex.europa.eu/legal-content/EN/TXT/PDF/?uri=CELEX:32016R0679"
    }
  },
  "view": "View",
  "footer": {
    "code1": "Authorization 10129614 / 0",
    "code2": "RCS B255694"
  }
}