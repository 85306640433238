<template>
  <header class="header navbar-fixed-top">
            <!-- Navbar -->
            <nav class="navbar" role="navigation">
                <div class="container">
                    <!-- Brand and toggle get grouped for better mobile display -->
                    <div class="menu-container js_nav-item">
                        <button type="button" class="navbar-toggle" data-toggle="collapse" data-target=".nav-collapse">
                            <span class="sr-only">Toggle navigation</span>
                            <span class="toggle-icon"></span>
                        </button>

                        <!-- Logo -->
                        <div class="logo">
                            <a class="logo-wrap" href="#body">
                                <img class="logo-img" src="static/img/logo.svg" alt="Jubo Service Logo">
                            </a>
                        </div>
                        <!-- End Logo -->
                    </div>

                    <!-- Collect the nav links, forms, and other content for toggling -->
                    <div class="collapse navbar-collapse nav-collapse">
                        <div class="menu-container">
                            <ul class="nav navbar-nav navbar-nav-right">
                                <li class="js_nav-item nav-item"><a class="nav-item-child nav-item-hover" href="#body">{{ $t('default.home.header') }}</a></li>
                                <li class="js_nav-item nav-item"><a class="nav-item-child nav-item-hover" href="#about">{{ $t('default.about.header') }}</a></li>
                                <li class="js_nav-item nav-item"><a class="nav-item-child nav-item-hover" href="#services">{{ $t('default.services.header') }}</a></li>
                                <li class="js_nav-item nav-item"><a class="nav-item-child nav-item-hover" href="#fees">{{ $t('default.fees.header') }}</a></li>
                                <li class="js_nav-item nav-item"><a class="nav-item-child nav-item-hover" href="#partners">{{ $t('default.partners.header') }}</a></li>
                                <li class="js_nav-item nav-item"><a class="nav-item-child nav-item-hover" href="#contact">{{ $t('default.contact.header') }}</a></li>
                            </ul>
                        </div>
                    </div>
                    <!-- End Navbar Collapse -->
                </div>
            </nav>
            <!-- Navbar -->
            <div class="btn-group" role="group" aria-label="Basic example">
              <button type="button" class="btn btn-outline-primary" v-for="entry in languages" :key="entry.title" @click="changeLocale(entry.language)"><flag :iso="entry.flag" v-bind:squared=false /> {{ $t(`default.${entry.title}`) }}</button>
            </div>
  </header>
  <div class="promo-block">
            <div class="container">
                <div class="row">
                    <div class="col-sm-6 sm-margin-b-60">
                        <div class="margin-b-30">
                            <h2 class="promo-block-title">Biagui Alexandre<br/> Nadialine</h2>
                            <p class="promo-block-text">{{ $t('default.title') }}</p>
                        </div>

                        <ul class="list-inline">
                            <li><a href="https://web.facebook.com/Jubo-Service-de-M%C3%A9diation-et-Consultance-JSMC-S%C3%A0rl-S-110463831271389" target="_blank" class="social-icons"><i class="icon-social-facebook"></i></a></li>
                            <li><a href="https://twitter.com/jubo_de" target="_blank" class="social-icons"><i class="icon-social-twitter"></i></a></li>
                            <li><a href="https://www.linkedin.com/company/jubo-service-de-m%C3%A9diation-et-consultance-jsmc-s-%C3%A0-r-l-s" target="_blank" class="social-icons"><i class="icon-social-linkedin"></i></a></li>
                        </ul>
                    </div>
                    <div class="col-sm-6">
                        <div class="promo-block-img-wrap">
                            <img class="promo-block-img img-responsive" src="static/img/avatar.png" align="Avatar">
                        </div>
                    </div>
                </div>
                <!--// end row -->
            </div>
  </div>
  <!-- About -->
  <div id="about">
      <div class="container content-lg">
          <div class="row">
              <div class="col-sm-5 sm-margin-b-30">
                  <img class="full-width img-responsive" src="static/img/about.png" alt="About Image">
              </div>
              <div class="col-sm-7">
                  <div class="section-seperator margin-b-30">
                      <div class="margin-b-50">
                          <div class="margin-b-30">
                              <h2>{{ $t('default.about.title') }}</h2>
                              <p> 
                                {{ $t('default.home.message') }}
                              </p>
                              <h3>{{ $t('default.about.jubo.title') }}</h3>
                              <p>
                                {{ $t('default.about.jubo.message') }}
                              </p>

                              <h3>{{ $t('default.about.philosophy.title') }}</h3>
                              <p>
                                {{ $t('default.about.philosophy.message') }} 
                              </p>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <!--// end row -->
      </div>
  </div>
  <!-- End About -->
  <!-- Services -->
  <div id="services">
      <div class="bg-color-sky-light" data-auto-height="true">
          <div class="container content-lg">
              <div class="row row-space-2 margin-b-4">
                  <div class="col-md-6 col-sm-6">
                      <div class="service" data-height="height">
                          <div class="service-element">
                              <i class="service-icon icon-speech"></i>
                          </div>
                          <div class="service-info">
                              <h3>{{ $t('default.services.mediation.title') }}</h3>
                              <div id="parent">
                                <div class="panel">
                                    <div v-if="!services.mediation">
                                      <table class="table borderless">
                                        <thead>
                                          <tr>
                                            <th scope="col">{{ $t('default.offered_services') }}</th>
                                            <th scope="col"></th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>
                                            <td>{{ $t('default.services.fm') }}</td>
                                            <td>{{ $t('default.services.mm') }}</td>
                                          </tr>
                                          <tr>
                                            <td>{{ $t('default.services.cm') }}</td>
                                            <td>{{ $t('default.services.hm') }}</td>
                                          </tr>
                                          <tr>
                                            <td>{{ $t('default.services.nm') }}</td>
                                            <td>{{ $t('default.services.pm') }}</td>
                                          </tr>
                                          <tr>
                                            <td>{{ $t('default.services.pmi') }}</td>
                                            <td></td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <p class="margin-b-5"></p>
                                    </div>
                                    <div v-if="services.mediation">
                                    <p class="margin-b-5">{{ $t('default.services.mediation.message') }}</p>
                                    </div>
                                    <button v-on:click="services.mediation = !services.mediation" type="button" class="btn btn-default">{{ $t('default.read_more') }}</button>
                                </div>
                              </div>
                              <p class="margin-b-5"></p>
                          </div>   
                      </div>
                  </div>
                  <div class="col-md-6 col-sm-6">
                      <div class="service" data-height="height">
                          <div class="service-element">
                              <i class="service-icon icon-people"></i>
                          </div>
                          <div class="service-info">
                              <h3>{{ $t('default.services.conciliation.title') }}</h3>
                              <div id="parent">
                                <div class="panel">
                                    <div v-if="services.conciliation">
                                      <table class="table borderless">
                                        <thead>
                                          <tr>
                                            <th scope="col">{{ $t('default.offered_services') }}</th>
                                            <th scope="col"></th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>
                                            <td>{{ $t('default.services.fc') }}</td>
                                            <td>{{ $t('default.services.mc') }}</td>
                                          </tr>
                                          <tr>
                                            <td>{{ $t('default.services.cw') }}</td>
                                            <td>{{ $t('default.services.cc') }}</td>
                                          </tr>
                                          <tr>
                                            <td>{{ $t('default.services.nc') }}</td>
                                            <td>{{ $t('default.services.pc') }}</td>
                                          </tr>
                                          <tr>
                                            <td>{{ $t('default.services.pci') }}</td>
                                            <td></td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <p class="margin-b-5"></p>
                                    </div>
                                    <div v-if="!services.conciliation">
                                    <p class="margin-b-5">{{ $t('default.services.conciliation.message') }}</p>
                                    </div>
                                    <button v-on:click="services.conciliation = !services.conciliation" type="button" class="btn btn-default">{{ $t('default.read_more') }}</button>
                                </div>
                              </div>
                              
                              <p class="margin-b-5"></p>
                          </div>   
                      </div>
                  </div>
                  <div class="col-md-6 col-sm-6">
                      <div class="service" data-height="height">
                          <div class="service-element">
                              <i class="service-icon icon-globe"></i>
                          </div>
                          <div class="service-info">
                              <h3>{{ $t('default.services.human_security_consulting.title') }}</h3>
                              <p class="margin-b-5">{{ $t('default.services.human_security_consulting.message') }}</p>
                          </div>    
                      </div>
                  </div>
                  <div class="col-md-6 col-sm-6">
                      <div class="service" data-height="height">
                          <div class="service-element">
                              <i class="service-icon icon-badge"></i>
                          </div>
                          <div class="service-info">
                              <h3>{{ $t('default.services.research_and_proofreading.title') }}</h3>
                              <p class="margin-b-5">{{ $t('default.services.research_and_proofreading.message') }}</p>
                          </div>   
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
  <!-- End Services -->
  <div id="fees">
        <div class="container content-lg">
          <h4 class="card-title">{{ $t('default.fees.title') }}</h4>
          <div class="pricing-table table-responsive">
            <h5>{{ $t('default.fees.mediation') }}</h5>
            <table class="table">
              <!-- Heading -->
              <thead>
                <tr>
                  <th>&nbsp;</th>
                  <th>
                    {{ $t('default.fees.conventional') }}
                    <span class="ptable-star red">
                    </span>
                    <span class="ptable-price"></span>
                  </th>
                  <th class="highlight">
                    {{ $t('default.fees.judicial') }}
                    <span class="ptable-star green">
                    </span>
                    <span class="ptable-price"></span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><span class="ptable-title">{{ $t('default.fees.im') }}</span></td>
                  <td>
                    {{ $t('default.fees.free') }}
                  </td>
                  <td>
                    {{ $t('default.fees.free') }}
                  </td>
                </tr>
                <tr>
                  <td><span class="ptable-title">{{ $t('default.fees.fm') }}</span></td>
                  <td>
                    €57/ {{ $t('default.fees.hour') }}
                    <span>{{ $t('default.fees.bf') }}</span>
                  </td>
                  <td>                    €57/ {{ $t('default.fees.hour') }}
                    <span>{{ $t('default.fees.bf') }}</span>
                  </td>
                </tr>
                <tr>
                  <td><span class="ptable-title">{{ $t('default.fees.mm') }}</span></td>
                  <td>
                    €57/ {{ $t('default.fees.hour') }}
                    <span>{{ $t('default.fees.bs') }}</span>
                  </td>
                  <td>
                    €57/ {{ $t('default.fees.hour') }}
                    <span>{{ $t('default.fees.bs') }}</span>
                  </td>
                </tr>
                <tr>
                  <td><span class="ptable-title">{{ $t('default.fees.em') }}</span></td>
                  <td>
                    €150/ {{ $t('default.fees.hour') }}
                    <span>{{ $t('default.fees.iw') }}</span>
                  </td>
                  <td>
                    €150/ {{ $t('default.fees.hour') }}
                    <span>{{ $t('default.fees.iw') }}</span>
                  </td>
                </tr>
                <tr>
                  <td><span class="ptable-title">{{ $t('default.fees.cm') }}</span></td>
                  <td>
                    €120/ {{ $t('default.fees.hour') }}
                    <span>{{ $t('default.fees.hc') }}</span>
                  </td>
                  <td>
                    €120/ {{ $t('default.fees.hour') }}
                    <span>{{ $t('default.fees.hc') }}</span>
                  </td>
                </tr>
                <tr>
                  <td><span class="ptable-title">{{ $t('default.fees.nm') }}</span></td>
                  <td>
                    €100/ {{ $t('default.fees.hour') }}
                    <span>{{ $t('default.fees.neighbourhood') }}</span>
                  </td>
                  <td>
                    €100/ {{ $t('default.fees.hour') }}
                    <span>{{ $t('default.fees.neighbourhood') }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="pricing-table table-responsive">
            <h5>{{ $t('default.fees.conciliation') }}</h5>
            <table class="table">
              <!-- Heading -->
              <thead>
                <tr>
                  <th>&nbsp;</th>
                  <th>
                    {{ $t('default.fees.conventional') }}
                    <span class="ptable-star red">
                    </span>
                    <span class="ptable-price"></span>
                  </th>
                  <th class="highlight">
                    {{ $t('default.fees.judicial') }}
                    <span class="ptable-star green">
                    </span>
                    <span class="ptable-price"></span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><span class="ptable-title">{{ $t('default.fees.im') }}</span></td>
                  <td>
                    {{ $t('default.fees.free') }}
                  </td>
                  <td>
                    {{ $t('default.fees.free') }}
                  </td>
                </tr>
                <tr>
                  <td><span class="ptable-title">{{ $t('default.fees.fc') }}</span></td>
                  <td>
                    €57/ {{ $t('default.fees.hour') }}
                    <span>{{ $t('default.fees.bf') }}</span>
                  </td>
                  <td>       €57/ {{ $t('default.fees.hour') }}
                    <span>{{ $t('default.fees.bf') }}</span>
                  </td>
                </tr>
                <tr>
                  <td><span class="ptable-title">{{ $t('default.fees.mc') }}</span></td>
                  <td>
                    €57/ {{ $t('default.fees.hour') }}
                    <span>{{ $t('default.fees.bs') }}</span>
                  </td>
                  <td>
                    €57/ {{ $t('default.fees.hour') }}
                    <span>{{ $t('default.fees.bs') }}</span>
                  </td>
                </tr>
                <tr>
                  <td><span class="ptable-title">{{ $t('default.fees.ec') }}</span></td>
                  <td>
                    €150/ {{ $t('default.fees.hour') }}
                    <span>{{ $t('default.fees.iw') }}</span>
                  </td>
                  <td>
                    €150/ {{ $t('default.fees.hour') }}
                    <span>{{ $t('default.fees.iw') }}</span>
                  </td>
                </tr>
                <tr>
                  <td><span class="ptable-title">{{ $t('default.fees.cc') }}</span></td>
                  <td>
                    €120/ {{ $t('default.fees.hour') }}
                    <span>{{ $t('default.fees.hc') }}</span>
                  </td>
                  <td>
                    €120/ {{ $t('default.fees.hour') }}
                    <span>{{ $t('default.fees.hc') }}</span>
                  </td>
                </tr>
                <tr>
                  <td><span class="ptable-title">{{ $t('default.fees.nc') }}</span></td>
                  <td>
                    €100/ {{ $t('default.fees.hour') }}
                    <span>{{ $t('default.fees.neighbourhood') }}</span>
                  </td>
                  <td>
                    €100/ {{ $t('default.fees.hour') }}
                    <span>{{ $t('default.fees.neighbourhood') }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <h4 class="card-title">{{ $t('default.fees.td') }}</h4>
          <div class="row mt-4">
            <div class="col-md-6">
              <ul class="list-group">
                <li class="list-group-item">{{ $t('default.fees.vc') }}</li>
                <li class="list-group-item">{{ $t('default.fees.dc') }}</li>
                <li class="list-group-item">{{ $t('default.fees.cash') }}</li>
                <li class="list-group-item">{{ $t('default.fees.bt') }}</li>
              </ul>
            </div>
            <div class="col-md-6">
              <ul class="list-group">
                <li class="list-group-item">{{ $t('default.fees.pd.title') }} <span> {{ $t('default.fees.pd.message') }}</span></li>
                <li class="list-group-item">{{ $t('default.fees.penalty.title') }} <span> {{ $t('default.fees.penalty.message') }}</span></li>
                <li class="list-group-item">{{ $t('default.fees.cancellation.title') }} <span>{{ $t('default.fees.cancellation.message') }}</span></li>
                <li class="list-group-item">{{ $t('default.fees.penalty.title') }} <span>{{ $t('default.fees.penalty.message') }}</span></li>
              </ul>
            </div>
          </div>
        </div>
        <h4 class="card-title">{{ $t('default.partners.header') }}</h4>
  </div>
          <!-- Clients -->
  <div id="partners" class="bg-color-sky-light">
      <div class="content-lg container">
          <!-- Swiper Clients -->
          <div class="swiper-slider swiper-clients">
              <!-- Swiper Wrapper -->
              <div class="swiper-wrapper row">
                  <div class="swiper-slide col-md-4">
                      <img class="swiper-clients-img" src="static/img/uni_luxemborg.jpeg" alt="Clients Logo">
                  </div>
              </div>
              <!-- End Swiper Wrapper -->
          </div>
          <!-- End Swiper Clients -->
      </div>
  </div>
        <!-- End Clients -->
  <div id="contact">
    <div class="bg-light text-dark">
        <div class="container content-lg">
            <div class="row margin-b-40">
                <div class="col-sm-6">
                   <h2>{{ $t('default.contact.title') }}</h2>
                </div>
            </div>
            <!--// end row -->

            <div class="row">
                <div class="col-md-3 col-sm-6 mb-1">
                    <h4>{{ $t('default.contact.location') }}</h4>
                    <a href="#"> 1, Hautpstrooss, L-9753 Heinerscheid, Luxembourg</a>
                </div>
                <div class="col-md-3 col-sm-6 mb-1">
                    <h4>{{ $t('default.contact.phone') }}</h4>
                    <p>{{ $t('default.contact.mobile') }}: <a href="#">+352 621 463 345</a></p>
                    <p>{{ $t('default.contact.office') }}: <a href="#">+ 352 26 95 75 62</a></p>
                </div>
                <div class="col-md-3 col-sm-6 mb-1">
                    <h4>{{ $t('default.contact.email') }}</h4>
                    <a href="#"> biagui@jubomediation.lu</a>
                </div>
                <div class="col-md-3 col-sm-6">
                    <h4>{{ $t('default.contact.web') }}</h4>
                    <a href="http://www.jubomediation.lu">www.jubomediation.lu</a>
                </div>
            </div>
            <!--// end row -->
        </div>
    </div>
</div>
<footer class="footer">
    <div class="content container">
        <div class="row">
          <h6 class="text-uppercase fw-bold">  {{ $t('default.mediation_laws.title') }}</h6>
          <p>{{ $t('default.mediation_laws.laws[0]') }}  <span><a :href="$t('default.mediation_laws.links.0')" class="stretched-link">  {{ $t('default.view') }}</a> </span></p>
          <p>{{ $t('default.mediation_laws.laws[1]') }}  <span><a :href="$t('default.mediation_laws.links.1')" class="stretched-link">  {{ $t('default.view') }}</a> </span></p> 
          <p>{{ $t('default.mediation_laws.laws[2]') }} <span><a :href="$t('default.mediation_laws.links.2')" class="stretched-link">  {{ $t('default.view') }}</a>  </span></p>
          <p>{{ $t('default.mediation_laws.laws[3]') }}  <span><a :href="$t('default.mediation_laws.links.3')" class="stretched-link">  {{ $t('default.view') }}</a> </span></p>
          <p>{{ $t('default.mediation_laws.laws[4]') }} <span><a :href="$t('default.mediation_laws.links.4')">  {{ $t('default.view') }}</a> </span></p>
          <p>{{ $t('default.mediation_laws.laws[5]') }} <span><a :href="$t('default.mediation_laws.links.5')" class="stretched-link">  {{ $t('default.view') }}</a></span></p>
          <p>{{ $t('default.mediation_laws.laws[6]') }}  <span><a :href="$t('default.mediation_laws.links.6')">  {{ $t('default.view') }}</a> </span></p>
        </div>
        <div class="row">
            <div class="col-xs-6">
                <img class="footer-logo" src="static/img/logo.svg" alt="Jubo Mediation">
            </div>
            <div class="col-xs-6 text-right sm-text-left">
                <p class="margin-b-0"><a class="fweight-700" href="#body">Jubo</a> Service de Médiation et Consultance (JSMC) S.à.r.l.-S. </p>
                <p>{{ $t('default.footer.code1') }}</p><p>{{ $t('default.footer.code2') }}</p>
            </div>
        </div>
        <!--// end row -->
    </div>
</footer>

</template>

<script>
//import { useI18n } from "vue-i18n";
export default {
  name: 'Content',
  props: {
    msg: String
  },
  data() {
    return {
        languages: [
            { flag: 'us', language: 'en', title: 'English' },
            { flag: 'fr', language: 'fr', title: 'French' },
            { flag: 'de', language: 'de', title: 'German' }
        ],
        services: {
          mediaiton: true,
          conciliation: true,
          hsc: true,
          rnd: true,
        }
    };
  },
  methods: {
    changeLocale(locale) {
        this.$i18n.locale = locale;
    }
  }, 
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
